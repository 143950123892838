import { Layout, Row, Col } from 'antd';
import React from 'react';
import playstore from '../../assets/img/google-play-store.svg';
import appstore from '../../assets/img/apple-play-store.svg';
import shopSideImage from '../../assets/img/shop-side-image.png';
import logo from '../../assets/img/jingls-img/logo.png';
import './styles.scss';

const { Content, Header } = Layout;

const NotFound: React.FC = () => {
  return (
    <div>
      <Layout>
        <Header className="header">
          <Row>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} />
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="d-flex justify-content-center">
                <a href="/">
                  <img src={logo} className="header-logo" alt="jingls-logo" />
                </a>
              </div>
            </Col>
          </Row>
        </Header>
        <Content className="not-found-content">
          <section className="shop-section">
            <div className="container-fluid">
              <div className="shop-wrapper align-center">
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="shop-section-description">
                      <h2 className="text-white beba-bold section-heading">
                        Start your journey and discover your needs.
                      </h2>
                      <p className="para-16 text-white">
                        With unlimited brands and products to choose from,
                        experience the joy of exploring the digital journey of
                        virtual shopping. So what are you waiting for? Get
                        started now!
                      </p>
                    </div>
                    <div className="app-links d-flex">
                      <a href="/">
                        <img src={playstore} className="img-fluids" alt="" />
                      </a>
                      <a href="/">
                        <img src={appstore} className="img-fluids" alt="" />
                      </a>{' '}
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-end"
                  >
                    <div className="shop-side-image">
                      <img src={shopSideImage} className="img-fluids" alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </Content>
      </Layout>
    </div>
  );
};

export default NotFound;
