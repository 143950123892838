/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import './style.scss';
import logo from '../../assets/img/jingls-img/logo.png';
import playstore from '../../assets/img/google-play-store.svg';
import appstore from '../../assets/img/apple-play-store.svg';
// import consumerElectronics from '../../assets/img/jingls-lp-images/consumer-electronics.png';
// import consumerProduct from '../../assets/img/jingls-lp-mockup/consumer-product.png';
import boat from '../../assets/img/clients-gallery-slider/boat.png';
import boreChai from '../../assets/img/clients-gallery-slider/borechai.png';
import noise from '../../assets/img/clients-gallery-slider/noise.png';
import sleepOwl from '../../assets/img/clients-gallery-slider/sleep-owl.png';
import juicyChemistry from '../../assets/img/clients-gallery-slider/juicy-chemisty.png';
import twoBrothers from '../../assets/img/two-brothers.png';
import dailyFiber from '../../assets/img/daily-fiber.png';
import strive from '../../assets/img/strive.png';
import brb from '../../assets/img/brb.png';
import damensch from '../../assets/img/damensch.png';
import bonkersCorner from '../../assets/img/bonkers-corner.png';
import shopSideImage from '../../assets/img/shop-side-image.png';
import selectInterest from '../../assets/img/select-interest.png';
import exploreProduct from '../../assets/img/explore-products.png';
import selectCategory from '../../assets/img/select-categories.png';
import kaayInnovation from '../../assets/img/k-innovation.png';
import titleLogo from '../../assets/img/jingls-img/jingls.png';
import { Button, Carousel, Col, Form, Input, Layout, Row, Select } from 'antd';
const { Header, Footer, Content } = Layout;

const Home: React.FC = () => {
  const partnerRef = useRef<null | HTMLDivElement>(null);

  const scrollToView = (view: string) => {
    if (view === 'partner') {
      if (partnerRef.current as HTMLDivElement) {
        (partnerRef.current as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
        });
      }
    } else if (view === 'contact') {
    }
  };

  const handleSubmit = (values: any) => {
    console.log(values, 'value');
  };
  return (
    <div className="App">
      <Layout>
        <Header className="header">
          <Row>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className="d-flex align-center">
                <a href="/">
                  <img src={logo} className="header-logo" alt="jingls-logo" />
                </a>
              </div>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8}>
              <div className="d-flex align-center justify-content-center header-center-div">
                <Button
                  type="link"
                  onClick={() => scrollToView('partner')}
                  className=""
                >
                  <span>Partners</span>
                </Button>
                <span>.</span>
                <Button
                  type="link"
                  onClick={() => scrollToView('contact')}
                  className=""
                >
                  <span>Contact</span>
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className="d-flex justify-content-end align-center">
                <a href="https://play.google.com/store/apps/details?id=com.jinglebidconsumer&pli=1">
                  <img src={playstore} className="header-logo" alt="" />
                </a>
                <a href="https://apps.apple.com/us/app/jinglebid-buy-online-locally/id1514588147">
                  <img src={appstore} className="header-logo" alt="" />
                </a>
              </div>
            </Col>
          </Row>
        </Header>
        <Layout>
          <Content className="content">
            <div>
              <div>
                <div>
                  <Row>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                      className="d-flex justify-content-center text-center"
                    >
                      <div className="">
                        <h1 className="beba-bold section-heading text-center">
                          Explore Amazing Products
                        </h1>
                        <p className="nunito-regular para-18 text-center">
                          The ultimate product discovery and exploration
                          companion!
                        </p>
                      </div>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                  </Row>
                  {/* <div className='col-md-12'>
                <div className='swiper-container product_slider'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src={consumerElectronics}
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src={consumerProduct}
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>
                          Consumer Electronics
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/beauty-products.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/personal-care.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>
                          Beauty products & personal care
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/jewellery.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/jewellery.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Jewellery</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/footwear.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/footwear.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Footwear</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/aparel.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/apparel.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Apparel</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/photography.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/photography.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>
                          Camera & photography
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/books.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/books.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Books</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/baby-products.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/baby-products.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Baby Products</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/health-suppliments.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/supplements.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Supplements</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/home-and-kitchen-appliances.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/appliances.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Appliances</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/pet-supplies.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/pet-suppplies.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Pet Supplies</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/video-games.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/video-games.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Video Games</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/consumer-electronics.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/consumer-product.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>
                          Consumer Electronics
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/beauty-products.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/personal-care.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>
                          Beauty products & personal care
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/jewellery.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/jewellery.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Jewellery</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/footwear.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/footwear.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Footwear</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/aparel.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/apparel.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Apparel</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/photography.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/photography.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>
                          Camera & photography
                        </p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/books.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/books.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Books</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/baby-products.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/baby-products.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Baby Products</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/health-suppliments.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/supplements.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Supplements</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/home-and-kitchen-appliances.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/appliances.png'
                          className='img-fluid big'
                          alt=''
                        />
                        <p className='text-center description'>Appliances</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/pet-supplies.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/pet-suppplies.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Pet Supplies</p>
                      </div>
                    </div>
                    <div className='swiper-slide' data-swiper-autoplay='3000'>
                      <div className='product-slide'>
                        <img
                          src='assets/img/jingls-lp-images/video-games.png'
                          className='img-fluid small'
                          alt=''
                        />
                        <img
                          src='assets/img/jingls-lp-mockup/video-games.png'
                          className='img-fluid big'
                          alt=''
                        />

                        <p className='text-center description'>Video Games</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                  <section className="discover-section bg-red">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="section-description">
                            <h1 className="beba-bold cream-color section-heading text-uppercase nunito-bold text-center">
                              Unlimited brands. Unlimited products. Discover
                              them all with Jingls.
                            </h1>
                            <p className="cream-color para-18">
                              From trending apparel to latest gadgets, we have
                              everything you need. Jingls is the right place to
                              find the right product.
                            </p>
                          </div>
                          {/* <div className='swiper-container discover-slider'>
                            <div className='swiper-wrapper'>
                              <div className='swiper-slide'>
                                <div className='discover-slide'>
                                  <img
                                    src='assets/img/select-interest.png'
                                    className='img-fluid'
                                    alt=''
                                  />
                                </div>
                              </div>
                              <div className='swiper-slide'>
                                <div className='discover-slide'>
                                  <img
                                    src='assets/img/explore-products.png'
                                    className='img-fluid'
                                    alt=''
                                  />
                                </div>
                              </div>
                              <div className='swiper-slide'>
                                <div className='discover-slide'>
                                  <img
                                    src='assets/img/select-categories.png'
                                    className='img-fluid'
                                    alt=''
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className='swiper-pagination'
                              id='tab-pagination'
                            ></div>
                          </div> */}
                          <div className="discover-slider">
                            <Carousel autoplay style={{ paddingTop: '50px' }}>
                              <div>
                                <Row>
                                  <Col span={8}></Col>
                                  <Col
                                    span={8}
                                    className="d-flex justify-content-center"
                                  >
                                    <img
                                      src={selectInterest}
                                      // className='img-fluids'
                                      style={{ height: '300px' }}
                                      alt=""
                                    />
                                  </Col>
                                  <Col span={8}></Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col span={8}></Col>
                                  <Col
                                    span={8}
                                    className="d-flex justify-content-center"
                                  >
                                    <img
                                      src={exploreProduct}
                                      // className='img-fluids'
                                      style={{ height: '300px' }}
                                      alt=""
                                    />
                                  </Col>
                                  <Col span={8}></Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col span={8}></Col>
                                  <Col
                                    span={8}
                                    className="d-flex justify-content-center"
                                  >
                                    <img
                                      src={selectCategory}
                                      // className='img-fluids'
                                      style={{ height: '300px' }}
                                      alt=""
                                    />
                                  </Col>
                                  <Col span={8}></Col>
                                </Row>
                              </div>
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="client-gallery-section"
                    ref={partnerRef}
                    id="partners"
                  >
                    <div className="">
                      <div>
                        <div className="">
                          <div className="gallery-section-heading pr-5">
                            <h1 className="beba-bold section-heading">
                              Experience the ease of exploring unlimited options
                            </h1>
                            <p className="para-18">
                              Unlimited products from unlimited brands to choose
                              from! Explore a wide range of products across
                              various categories suited just for you!
                            </p>
                          </div>
                        </div>
                      </div>
                      <Carousel autoplay autoplaySpeed={3000}>
                        <div>
                          <Row className="">
                            <Col
                              span={8}
                              className="d-flex justify-content-center"
                            >
                              <div className="gallery-inner">
                                <img
                                  src={boat}
                                  className="img-fluids"
                                  alt="gallery-one"
                                />
                              </div>
                            </Col>
                            <Col span={16}>
                              <Row gutter={16} style={{ margin: '15px' }}>
                                <Col
                                  span={12}
                                  className="d-flex justify-content-center carousel-col"
                                >
                                  <img
                                    src={boreChai}
                                    className="img-fluids"
                                    alt="gallery-one"
                                  />
                                </Col>
                                <Col
                                  span={12}
                                  className="d-flex justify-content-center carousel-col"
                                >
                                  <img
                                    src={noise}
                                    className="img-fluids"
                                    alt="gallery-one"
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col
                                  span={12}
                                  className="d-flex justify-content-center carousel-col"
                                >
                                  <img
                                    src={sleepOwl}
                                    className="img-fluids mb-3"
                                    alt="gallery-one"
                                  />
                                </Col>
                                <Col
                                  span={12}
                                  className="d-flex justify-content-center carousel-col"
                                >
                                  <img
                                    src={juicyChemistry}
                                    className="img-fluids"
                                    alt="gallery-one"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row gutter={16} className="m-15">
                            <Col
                              span={8}
                              className="d-flex justify-content-center carousel-col"
                            >
                              <img
                                src={twoBrothers}
                                className="img-fluids"
                                alt="gallery-one"
                              />
                            </Col>
                            <Col
                              span={8}
                              className="d-flex justify-content-center carousel-col"
                            >
                              <div>
                                <img
                                  src={strive}
                                  className="img-fluids"
                                  alt="gallery-one"
                                />
                              </div>
                            </Col>
                            <Col span={8} className="carousel-col">
                              <img
                                src={brb}
                                className="img-fluids"
                                alt="gallery-one"
                              />
                            </Col>
                          </Row>
                          <Row gutter={16} className="m-15">
                            <Col span={8} className="carousel-col">
                              <img
                                src={dailyFiber}
                                className="img-fluids"
                                alt="gallery-one"
                              />
                            </Col>
                            <Col span={8} className="carousel-col">
                              <img
                                src={bonkersCorner}
                                className="img-fluids"
                                alt="gallery-one"
                              />
                            </Col>
                            <Col span={8} className="carousel-col">
                              <img
                                src={damensch}
                                className="img-fluids"
                                alt="gallery-one"
                              />
                            </Col>
                          </Row>
                        </div>
                      </Carousel>
                    </div>
                  </section>
                  <section className="shop-section">
                    <div className="container-fluid">
                      <div className="shop-wrapper align-center">
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <div className="shop-section-description">
                              <h2 className="text-white beba-bold section-heading">
                                Start your journey and discover your needs.
                              </h2>
                              <p className="para-16 text-white">
                                With unlimited brands and products to choose
                                from, experience the joy of exploring the
                                digital journey of virtual shopping. So what are
                                you waiting for? Get started now!
                              </p>
                            </div>
                            <div className="app-links d-flex">
                              <a href="/">
                                <img
                                  src={playstore}
                                  className="img-fluids"
                                  alt=""
                                />
                              </a>
                              <a href="/">
                                <img
                                  src={appstore}
                                  className="img-fluids"
                                  alt=""
                                />
                              </a>{' '}
                            </div>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            className="d-flex justify-content-end"
                          >
                            <div className="shop-side-image">
                              <img
                                src={shopSideImage}
                                className="img-fluids"
                                alt=""
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                  <div className="container-fluid">
                    <div className="cement-bg form-head-row">
                      <div className="d-flex justify-content-center  justify-content-md-start align-items-center text-center">
                        <h3 className="nunito-bold">Want to write to us</h3>
                      </div>
                      <div className="col-md-6 d-flex d-flex justify-content-center justify-content-md-end align-items-center text-center">
                        <a href="mailto:hello@jingls.com">
                          <button className="transparent-btn">
                            {' '}
                            hello@jingls.com &nbsp;{' '}
                            <i className="fa-regular fa-arrow-up-right"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="enquiry-form">
              <div className="contact-form-heading">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="text-center  mt-5 beba-bold section-heading">
                      Engage Your Brand with The Jingls Community!
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <Form layout="vertical" onFinish={handleSubmit}>
                  <Row>
                    <Col xs={0} sm={0} md={6} lg={6} xl={6}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your First Name!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your Last Name!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="Contact Number"
                            name="phoneNumber"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your phone number!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your Email!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                              {
                                required: true,
                                message: 'Please select your category!',
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="Apparel">
                                Apparel
                              </Select.Option>
                              <Select.Option value="Consumer electronics">
                                Consumer electronics
                              </Select.Option>
                              <Select.Option value="Footwear">
                                Footwear
                              </Select.Option>
                              <Select.Option value="Home and kitchen appliances">
                                Home and kitchen appliances
                              </Select.Option>
                              <Select.Option value="Food and health supplements">
                                Food and health supplements
                              </Select.Option>
                              <Select.Option value="Baby products">
                                Baby products
                              </Select.Option>
                              <Select.Option value="Beauty products & personal care">
                                Beauty products & personal care
                              </Select.Option>
                              <Select.Option value="Fitness equipments">
                                Fitness equipments
                              </Select.Option>
                              <Select.Option value="Pet supplies">
                                Pet supplies
                              </Select.Option>
                              <Select.Option value="Books">Books</Select.Option>
                              <Select.Option value="Video games">
                                Video games
                              </Select.Option>
                              <Select.Option value="Camera & photography">
                                Camera & photography
                              </Select.Option>
                              <Select.Option value="Electric Vechiles">
                                Electric Vechiles
                              </Select.Option>
                              <Select.Option value="Others">
                                Others
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item label="Message">
                            <Input.TextArea
                              autoSize={{ minRows: 7, maxRows: 7 }}
                            />
                          </Form.Item>
                          <div className="submit-btn">
                            <Button type="default" htmlType="submit">
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={0} sm={0} md={6} lg={6} xl={6}></Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer
          className="section-padding bg-red nunito-bold text-white home-footer"
          id="footer"
        >
          <div className="container-fluid">
            <div>
              <div>
                <Row gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={6}
                    lg={6}
                    xl={6}
                    className="d-flex align-center"
                  >
                    <div className="footer-logo">
                      <img
                        src={titleLogo}
                        className="footer-title-logo"
                        alt=""
                      />
                      <div className="kay-info">
                        <p className="para-18 nunito-regular">
                          A Kaay Innovation Company
                        </p>
                        <a href="https://www.kaayinnovation.com/">
                          <img
                            src={kaayInnovation}
                            className="img-fluid"
                            style={{ height: '20px' }}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <div className="center-div">
                      <p className="m-3 para-20 footer-heading">Contact</p>
                      <ul>
                        <li>
                          <a
                            className="text-white para-20 footer-link"
                            href="mailto:hello@jingls.com"
                          >
                            -hello@jingls.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <p className="m-3 para-20 footer-heading">Others</p>
                    <ul>
                      <li>
                        <a href={'/privacy-policy'}>
                          <p className="privacy-text">-Privacy Policy</p>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-white para-20 footer-link">
                          <p>-Terms of use</p>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-white para-20 footer-link">
                          -Support
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <div className="d-flex justify-content-start justify-content-md-center social-col">
                      <div className="center-div" id="social">
                        <p className="m-3 para-20 footer-heading">Social</p>
                        <div className="social-media">
                          <a
                            href="https://instagram.com/columbus_discovery?igshid=MzRlODBiNWFlZA=="
                            className="text-white para-28 m-3 footer-link"
                          >
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                          <a
                            href="https://www.tiktok.com/@columbus_discovery?is_from_webapp=1&sender_device=pc"
                            className="text-white para-28 m-3 footer-link"
                          >
                            <i className="fa-brands fa-tiktok"></i>
                          </a>
                          <a
                            href="https://www.linkedin.com/company/jingle-bid/"
                            className="text-white para-28 m-3 footer-link"
                          >
                            <i className="fa-brands fa-linkedin"></i>
                          </a>
                          <a
                            href="https://www.youtube.com/@jinglebid7770"
                            className="text-white para-28 m-3 footer-link"
                          >
                            <i className="fa-brands fa-youtube"></i>
                          </a>
                        </div>
                        <div className="mobile-app-link">
                          <a href="/" className="g-play-link mb-3 mr-3">
                            <img
                              src="assets/img/google-play-store.svg"
                              className="img-fluid mb-3"
                              alt=""
                            />
                          </a>
                          <a href="/" className="a-play-link mb-3">
                            <img
                              src="assets/img/apple-play-store.svg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

export default Home;
