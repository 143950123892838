import { Layout, Row, Col, Button, Form, Input } from 'antd';
import React from 'react';
import './account.scss';
import logo from '../../assets/img/jingls-img/logo.png';
import playstore from '../../assets/img/google-play-store.svg';
import appstore from '../../assets/img/apple-play-store.svg';
const { Content, Header } = Layout;

const DeleteAccount: React.FC = () => {
  return (
    <div className='account-container'>
      <Layout>
        <Header>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='d-flex align-center justify-content-center'>
                <a href='/'>
                  <img src={logo} className='header-logo' alt='jingls-logo' />
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='d-flex align-center'>
                <a href='https://play.google.com/store/apps/details?id=com.jinglebidconsumer&pli=1'>
                  <img src={playstore} className='header-logo' alt='' />
                </a>
                &nbsp;&nbsp;
                <a href='https://apps.apple.com/us/app/jinglebid-buy-online-locally/id1514588147'>
                  <img src={appstore} className='header-logo' alt='' />
                </a>
              </div>
            </Col>
          </Row>
        </Header>
        <Content>
          <div>
            <div>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={{ offset: 8, span: 8 }}
                  lg={{ offset: 8, span: 8 }}
                  xl={{ offset: 8, span: 8 }}
                >
                  <div className='delete-account-container'>
                    <h3>Submit Your Details to Delete your Account</h3>
                    <div>
                      <Form layout='vertical'>
                        <Form.Item label='Email'>
                          <Input placeholder='Enter your Email' type='email' />
                        </Form.Item>
                        <Form.Item label='Phone Number'>
                          <Input
                            placeholder='Enter your Phone Number'
                            type='email'
                          />
                        </Form.Item>
                        <div className='submit-btn'>
                          <Button htmlType='submit'>Sumbit</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default DeleteAccount;
