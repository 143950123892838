import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from '../pages/home';
import Account from '../pages/account';
import DeleteAccount from '../pages/account/delete-account';
import NotFound from '../pages/notFound';
import PrivacyPolicy from '../pages/privacyPolicy';

const Routers: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account/delete" element={<DeleteAccount />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
