/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row, Layout } from 'antd';
import React from 'react';
import logo from '../../assets/img/jingls-img/logo.png';
import './styles.scss';
import { Link } from 'react-router-dom';
import kaayInnovation from '../../assets/img/k-innovation.png';
import titleLogo from '../../assets/img/jingls-img/jingls.png';

const { Header, Content, Footer } = Layout;

const PrivacyPolicy: React.FC = () => (
  <Layout className="privacy-policy">
    <Header>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 14, offset: 5 }}
          xl={{ span: 14, offset: 5 }}
          className="ml-15rem"
        >
          <img src={logo} alt="white-logo" className="logo" />
        </Col>
      </Row>
    </Header>
    <Content>
      <div className="pt-20 bg-white ">
        <div className="text-center">
          <h2 className="fs-20 fw-600">
            Privacy Policy And Management Of User Data (“Privacy Policy”)
          </h2>
        </div>
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 14, offset: 5 }}
            xl={{ span: 14, offset: 5 }}
          >
            <h5 className="fs-16 fw-600">Introduction</h5>
            <p className="fs-14">
              Welcome to Jingls the tech platform dedicated to helping you
              discover and connect with emerging direct-to-consumer (D2C)
              brands. Our services allow you to explore product feeds and, with
              a single click, visit the brand's own website to make purchases
              directly. This Privacy Policy describes how we collect, use, and
              share information about you through our mobile application and
              associated services.
            </p>
            <h5 className="fs-16 fw-600">1. Information We Collect</h5>
            <p>
              We collect the following types of information about you:
              <ul>
                <li>
                  - Account Information: When you create an account, we collect
                  your email address, phone number, age, and gender.
                </li>
                <li>
                  - Interaction Data: We collect data about how you interact
                  with our app, including which products you click on and the
                  times you access certain features.
                </li>
              </ul>
            </p>
            <h5 className="fs-16 fw-600">2. How We Use Your Information</h5>
            <p>
              Your information helps us:
              <ul>
                <li>- Provide, operate, and improve our app</li>
                <li>
                  - Communicate with you about updates, services, and
                  promotional offers
                </li>
                <li>
                  - Understand how users interact with our app to tailor and
                  enhance the user experience
                </li>
                <li>
                  - Comply with legal obligations and resolve any disputes that
                  we may have
                </li>
              </ul>
            </p>

            <h5 className="fs-16 fw-600">
              3. Cookies and Tracking Technologies
            </h5>
            <p>
              Our app uses cookies and other tracking technologies to enhance
              your browsing experience, analyze app traffic, and serve targeted
              advertisements. You can control the use of cookies at the
              individual browser level, but if you choose to disable cookies, it
              may limit your use of certain features or functions on our app.
            </p>
            <h5 className="fs-16 fw-600">Sharing of Your Information</h5>
            <p>We may share your information as follows:</p>
            <ul>
              <li>
                - D2C Brands: When you click on a product that directs you to a
                D2C brand's website, especially those designed by our partner
                Zupain, we may share certain information with Zupain to enhance
                your shopping experience.
              </li>
              <li>
                - Service Providers: We use third-party service providers to
                help us with various tasks, including data hosting and
                analytics. These providers are contractually obligated to
                protect your information and use it only as necessary to perform
                their services on our behalf.
              </li>
              <li>
                - Legal Requirements: We may disclose your information if
                required by law or if we believe in good faith that such action
                is necessary to comply with the law, prevent fraud, or protect
                our rights.
              </li>
            </ul>
            <h5 className="fs-16 fw-600">Data Retention</h5>
            <p>
              We retain personal information we collect from you where we have
              an ongoing legitimate business need to do so (for example, to
              provide you with a service you have requested or to comply with
              applicable legal, tax, or accounting requirements).
            </p>
            <h5 className="fs-16 fw-600">International Data Transfers</h5>
            <p>
              Information that we collect may be stored and processed in and
              transferred between any of the countries in which we operate to
              enable us to use the information in accordance with this policy.
              In these cases, we will take appropriate measures to ensure that
              your personal information receives an adequate level of protection
              as required by relevant laws.
            </p>
            <h5 className="fs-16 fw-600">Children’s Privacy</h5>
            <p>
              Our app is not intended for children under the age of [13/16/18]
              (as applicable based on local laws). We do not knowingly collect
              personal data from children under these ages. If we learn that we
              have collected personal information from a child under such age
              limits, we will take steps to delete the information as soon as
              possible
            </p>
            <h5 className="fs-16 fw-600">Security of Your Information</h5>
            <p>
              We take the security of your data seriously and use appropriate
              technical and organizational measures to protect your personal
              information from unauthorized access, alteration, or destruction.
            </p>
            <h5 className="fs-16 fw-600">Your Rights</h5>
            <p>
              You have the right to access, correct, or delete the personal
              information we collect. You can also object to the processing of
              your personal information, ask us to restrict processing of your
              personal information or request portability of your personal
              information. If we have collected and process your personal
              information with your consent, then you can withdraw your consent
              at any time.
            </p>
            <h5 className="fs-16 fw-600">Changes to our policy</h5>
            <p>
              We may update this Privacy Policy from time to time. The latest
              version will always be posted on our website. We will notify you
              of any significant changes through our app or via email.
            </p>
            <h5 className="fs-16 fw-600">Contact Us</h5>
            <p>
              If you have any questions about this Privacy Policy or our data
              practices, please contact us at: <br />
              Email: hello@jingls.com
            </p>
            <p>
              Address:
              <br />
              Jingls
              <br />
              3-675, Valmiki Nagar, Kaveri Nagar, Lakshman Perumal Nagar,
              Chennai, Tamil Nadu 600041
            </p>
          </Col>
        </Row>
      </div>
    </Content>
    <Footer
      className="section-padding bg-red nunito-bold text-white privacy-footer"
      id="footer"
    >
      <div className="container-fluid">
        <div>
          <div>
            <Row gutter={16}>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                className="d-flex align-center"
              >
                <div className="footer-logo">
                  <img src={titleLogo} className="footer-title-logo" alt="" />
                  <div className="kay-info">
                    <p className="para-18 nunito-regular">
                      A Kaay Innovation Company
                    </p>
                    <a href="https://www.kaayinnovation.com/">
                      <img
                        src={kaayInnovation}
                        className="img-fluid"
                        style={{ height: '20px' }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="center-div">
                  <p className="m-3 para-20 footer-heading">Contact</p>
                  <ul>
                    <li>
                      <a
                        className="text-white para-20 footer-link"
                        href="mailto:hello@jingls.com"
                      >
                        -hello@jingls.com
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <p className="m-3 para-20 footer-heading">Others</p>
                <ul>
                  <li>
                    <Link to={'/privacy-policy'}>
                      <p className="privacy-text">-Privacy Policy</p>
                    </Link>
                  </li>
                  <li>
                    <a href="#" className="text-white para-20 footer-link">
                      <p>-Terms of use</p>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white para-20 footer-link">
                      -Support
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="d-flex justify-content-start justify-content-md-center social-col">
                  <div className="center-div" id="social">
                    <p className="m-3 para-20 footer-heading">Social</p>
                    <div className="social-media">
                      <a
                        href="https://instagram.com/columbus_discovery?igshid=MzRlODBiNWFlZA=="
                        className="text-white para-28 m-3 footer-link"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.tiktok.com/@columbus_discovery?is_from_webapp=1&sender_device=pc"
                        className="text-white para-28 m-3 footer-link"
                      >
                        <i className="fa-brands fa-tiktok"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/jingle-bid/"
                        className="text-white para-28 m-3 footer-link"
                      >
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@jinglebid7770"
                        className="text-white para-28 m-3 footer-link"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </div>
                    <div className="mobile-app-link">
                      <a href="/" className="g-play-link mb-3 mr-3">
                        <img
                          src="assets/img/google-play-store.svg"
                          className="img-fluid mb-3"
                          alt=""
                        />
                      </a>
                      <a href="/" className="a-play-link mb-3">
                        <img
                          src="assets/img/apple-play-store.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Footer>
  </Layout>
);

export default PrivacyPolicy;
